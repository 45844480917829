import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OnboardingComponent } from '../core/components/onboarding/onboarding.component';
import { AuthStore } from '../state/auth/auth.store';
import { filter, map, Observable, switchMap, take, tap } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { CountryCode, RemoteConfigParameter } from '@customer-apps/shared/enums';
import { PARTNER_PORTAL_ROUTES } from '../shared/utils';
import { SettingsStore } from '../state/settings/settings.store';
import { WindowService } from '@customer-apps/shared/services';
import { OnboardingItem } from '@customer-apps/shared/interfaces';
import { RemoteConfigService } from './remote-config.service';

@Injectable({
    providedIn: 'root'
})
export class OnboardingService {
    constructor(
        private matDialog: MatDialog,
        private remoteConfig: RemoteConfigService,
        private settingsStore: SettingsStore,
        private windowService: WindowService,
        private authStore: AuthStore,
        private environment: EnvironmentService
    ) {}

    public getSlides(): OnboardingItem[] {
        return [
            {
                imageUrl: '/assets/images/onboarding/variants.svg',
                title: 'ONBOARDING.VARIANTS.TITLE',
                description: 'ONBOARDING.VARIANTS.DESCRIPTION'
            },
            { imageUrl: '/assets/images/onboarding/hp.svg', title: 'ONBOARDING.HP.TITLE', description: 'ONBOARDING.HP.DESCRIPTION' },
            {
                imageUrl: '/assets/images/onboarding/pdf-export.png',
                title: 'ONBOARDING.REPORT.TITLE',
                description: 'ONBOARDING.REPORT.DESCRIPTION'
            }
        ];
    }

    /**
     * Opens onboarding dialog when user has not seen the new features yet
     * according to the last onboarded version seaved in localeStorage.
     */
    public openDialog(): void {
        if (!this.isOnboardingTurnedOn() || this.hasDoneOnboarding() || !this.hasSlides()) {
            return;
        }

        this.authStore.isViplanDefault$
            .pipe(
                filter(Boolean),
                take(1),
                switchMap(() =>
                    this.matDialog
                        .open(OnboardingComponent, {
                            width: '720px'
                        })
                        .afterClosed()
                ),
                tap(() => {
                    this.setOnboardingAsDone();
                })
            )
            .subscribe();
    }

    /**
     * Returns url of VGP release notes according to the country selected in the country language switcher.
     */
    public getFAQUrl$(): Observable<string> {
        return this.settingsStore.countryCode$.pipe(
            map(countryCode => {
                return this.environment.getPartnerPortalUrl({
                    countryCodeSource: countryCode as CountryCode,
                    path: PARTNER_PORTAL_ROUTES.RELEASE_NOTES
                });
            })
        );
    }

    /**
     * Saves informtaion about last onboarded version in localeStorage.
     */
    private setOnboardingAsDone(): void {
        const key = this.environment.onboardingLocalStorageKey;
        this.windowService.localStorage.setItem(key, this.environment.version);
    }

    private hasDoneOnboarding(): boolean {
        const key = this.environment.onboardingLocalStorageKey;
        const lastOnboardingVersion = this.windowService.localStorage.getItem(key);
        return lastOnboardingVersion === this.remoteConfig.config[RemoteConfigParameter.OnboardingVersion];
    }

    private isOnboardingTurnedOn(): boolean {
        return Boolean(this.remoteConfig.config[RemoteConfigParameter.OnboardingVersion]);
    }

    private hasSlides(): boolean {
        return !!this.getSlides()?.length;
    }
}
